"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var editFactoringStore_1 = require("@/state/settings/editFactoringStore");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {};
  },
  computed: {
    rechenzentren: function rechenzentren() {
      return editFactoringStore_1.editFactoringStore.getters.clientRechenZentrenInfo;
    }
  },
  methods: {
    updateRechenzentrum: function updateRechenzentrum(value, id) {
      if (value) {
        editSettingsStore_1.editSettingsStore.commit.addRechenzentrumReihenfolge(id);
      } else {
        editSettingsStore_1.editSettingsStore.commit.deleteRechenzentrumReihenfolge(id);
      }
    }
  }
});