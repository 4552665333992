/* unplugin-vue-components disabled */import __unplugin_components_1 from '/builds/rose-metrics/rose-metrics/vui/src/components/StichwortChip.vue';
import __unplugin_components_0 from '/builds/rose-metrics/rose-metrics/vui/src/components/StichwortChip.vue';
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"color":"grey lighten-3","flat":""}},[_c(VOverlay,{attrs:{"value":_vm.isInactive,"absolute":true,"z-index":"1"}},[_c(VAlert,{attrs:{"prominent":"","type":"info","icon":"fas fa-shield"}},[_c('h2',{staticClass:"font-weight-bold"},[_vm._v("INAKTIV")])])],1),_c(VContainer,{staticClass:"px-3",attrs:{"fluid":""}},[_c(VRow,{staticClass:"mb-1 align-center"},[_c(VCol,{staticClass:"pt-0",attrs:{"md":"2"}},[_c(VAutocomplete,{attrs:{"items":_vm.stichworteAvailable,"item-value":"name","item-text":"name","placeholder":"Auswählen","hide-details":"","persistent-placeholder":"","solo":"","flat":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItemContent,[_c(__unplugin_components_0,{attrs:{"stichwort":item,"is-addable":true,"on":on,"attrs":attrs}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c(__unplugin_components_1,{attrs:{"stichwort":item,"on":on,"attrs":attrs}})]}}]),model:{value:(_vm.stichwortSelected),callback:function ($$v) {_vm.stichwortSelected=$$v},expression:"stichwortSelected"}})],1),_c(VCol,{staticClass:"pt-0",attrs:{"md":"2"}},[_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.stichwortSelected},on:{"click":function($event){return _vm.addRZStichwort()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("fa-plus")]),_c('div',{staticClass:"pl-2"},[_vm._v("Hinzufügen")])],1)],1)],1),_c('ag-grid-vue',{staticClass:"d-block ag-theme-balham",attrs:{"column-defs":_vm.columnDefs,"row-data":_vm.rzStichworte,"locale-text":_vm.localeText,"grid-options":_vm.gridOptions,"overlay-loading-template":_vm.overlayLoadingTemplate,"overlay-no-rows-template":_vm.overlayNoRowsTemplate},on:{"grid-ready":_vm.onGridReady,"cellClicked":_vm.onCellClicked}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }