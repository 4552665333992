/* unplugin-vue-components disabled */import __unplugin_components_1 from '/builds/rose-metrics/rose-metrics/vui/src/components/StichwortChip.vue';
import __unplugin_components_0 from '/builds/rose-metrics/rose-metrics/vui/src/components/StichwortChip.vue';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VCard,{staticClass:"mt-1 px-3 py-4",attrs:{"color":"grey lighten-3"}},[_c(VRow,{staticClass:"align-center mb-1"},[_c(VCol,{attrs:{"md":"4","sm":"12","xs":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.zvStichworteAvailable,"item-value":"name","item-text":"name","label":"Stichworte","placeholder":"Stichworte","no-data-text":"Keine Stichworte vorhanden","solo":"","flat":"","dense":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItemContent,[_c(__unplugin_components_0,{attrs:{"stichwort":item,"is-addable":true,"on":on,"attrs":attrs}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c(__unplugin_components_1,{attrs:{"stichwort":item,"on":on,"attrs":attrs}})]}}]),model:{value:(_vm.stichwortToAddStichwort),callback:function ($$v) {_vm.stichwortToAddStichwort=$$v},expression:"stichwortToAddStichwort"}})],1),_c(VCol,{attrs:{"md":"5","sm":"12","xs":"12"}},[_c(VTextField,{attrs:{"label":"Anzeigename","solo":"","dense":"","flat":"","hide-details":""},model:{value:(_vm.stichwortToAddText),callback:function ($$v) {_vm.stichwortToAddText=$$v},expression:"stichwortToAddText"}})],1),_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.stichwortToAddStichwort || !_vm.stichwortToAddText},on:{"click":function($event){return _vm.addZVStichwort()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("fa-plus")]),_c('div',{staticClass:"pl-2"},[_vm._v("Hinzufügen")])],1)],1)],1),_c('ag-grid-vue',{staticClass:"d-block ag-theme-balham",attrs:{"column-defs":_vm.columnDefs,"row-data":_vm.zvStichworteKeys,"locale-text":_vm.localeText,"grid-options":_vm.gridOptions,"overlay-loading-template":_vm.overlayLoadingTemplate,"overlay-no-rows-template":_vm.overlayNoRowsTemplate,"components":_vm.components},on:{"grid-ready":_vm.onGridReady,"cellClicked":_vm.onCellClicked}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }