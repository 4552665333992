"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsDiversRechenzentrumColumnsDefs = void 0;
const editSettingsStore_1 = require("@/state/settings/editSettingsStore");
function settingsDiversRechenzentrumColumnsDefs() {
    return [
        {
            headerName: 'Stichwort',
            field: 'stichwort',
            cellRenderer: 'StichwortCell',
            valueGetter: params => { var _a, _b, _c; return ({ farbe: (_a = params.data) === null || _a === void 0 ? void 0 : _a.farbe, name: (_b = params.data) === null || _b === void 0 ? void 0 : _b.name, kuerzel: (_c = params.data) === null || _c === void 0 ? void 0 : _c.kuerzel }); },
            sortable: true,
            sort: 'asc',
            width: 180,
        },
        {
            headerName: 'AVA-Datum',
            headerComponent: 'HelpTooltipHead',
            headerComponentParams: {
                context: {
                    tooltipText: 'Datum wann die AVA angefragt wurde. Es wird dann die oben genannte AVA-Gültigkeit aufaddiert.',
                },
            },
            field: 'ava',
            valueGetter: params => { var _a, _b; return ({ value: (_a = params.data) === null || _a === void 0 ? void 0 : _a.details.ava, key: (_b = params.data) === null || _b === void 0 ? void 0 : _b.name }); },
            sortable: false,
            cellRenderer: 'DateSelectCell',
            cellRendererParams: {
                context: {
                    label: 'AVA-Datum',
                    clicked(field) {
                        console.log('field', field);
                        editSettingsStore_1.editSettingsStore.commit.setRZStichworteDetails({ [field.key]: { ava: field.value } });
                    },
                },
            },
            width: 180,
        },
        {
            headerName: 'AVA-OK',
            headerComponent: 'HelpTooltipHead',
            headerComponentParams: {
                context: {
                    tooltipText: 'Wenn dieses Stichwort bedeutet, dass die AVA ok ist, dann hier ein Häkchen setzen',
                },
            },
            field: 'avaOK',
            valueGetter: params => { var _a, _b; return ({ value: (_a = params.data) === null || _a === void 0 ? void 0 : _a.details.avaOK, key: (_b = params.data) === null || _b === void 0 ? void 0 : _b.name }); },
            sortable: false,
            cellRenderer: 'CheckboxCell',
            cellStyle: { display: 'flex', 'justify-content': 'center', 'align-items': 'center' },
            cellRendererParams: {
                context: {
                    clicked(field) {
                        editSettingsStore_1.editSettingsStore.commit.setRZStichworteDetails({ [field.key]: { avaOK: field.value } });
                    },
                },
            },
            width: 100,
        },
        {
            headerName: 'EWE-Datum',
            headerComponent: 'HelpTooltipHead',
            headerComponentParams: {
                context: {
                    tooltipText: 'Datum wann die EWE unterschrieben wurde',
                },
            },
            field: 'ewe',
            valueGetter: params => { var _a, _b; return ({ value: (_a = params.data) === null || _a === void 0 ? void 0 : _a.details.ewe, key: (_b = params.data) === null || _b === void 0 ? void 0 : _b.name }); },
            sortable: false,
            cellRenderer: 'DateSelectCell',
            cellRendererParams: {
                context: {
                    label: 'EWE-Datum',
                    clicked(field) {
                        editSettingsStore_1.editSettingsStore.commit.setRZStichworteDetails({ [field.key]: { ewe: field.value } });
                    },
                },
            },
            width: 180,
        },
        {
            headerName: 'Spezial-Fall',
            headerComponent: 'HelpTooltipHead',
            headerComponentParams: {
                context: {
                    tooltipText: 'Dieses Stichwort hat Vorrang und wird bevorzugt angezeigt',
                },
            },
            field: 'spezialFall',
            valueGetter: params => { var _a, _b; return ({ value: (_a = params.data) === null || _a === void 0 ? void 0 : _a.details.spezialFall, key: (_b = params.data) === null || _b === void 0 ? void 0 : _b.name }); },
            sortable: false,
            cellRenderer: 'CheckboxCell',
            cellStyle: { display: 'flex', 'justify-content': 'center', 'align-items': 'center' },
            cellRendererParams: {
                context: {
                    clicked(field) {
                        editSettingsStore_1.editSettingsStore.commit.setRZStichworteDetails({ [field.key]: { spezialFall: field.value } });
                    },
                },
            },
            width: 120,
        },
        {
            headerName: 'Spezial-Fall-Alarm',
            headerComponent: 'HelpTooltipHead',
            headerComponentParams: {
                context: {
                    tooltipText: 'Soll dieses Stichwort einen Alarm auslösen?',
                },
            },
            field: 'spezialFallAlarm',
            valueGetter: params => { var _a, _b; return ({ value: (_a = params.data) === null || _a === void 0 ? void 0 : _a.details.spezialFallAlarm, key: (_b = params.data) === null || _b === void 0 ? void 0 : _b.name }); },
            sortable: false,
            cellRenderer: 'CheckboxCell',
            cellStyle: { display: 'flex', 'justify-content': 'center', 'align-items': 'center' },
            cellRendererParams: {
                context: {
                    clicked(field) {
                        editSettingsStore_1.editSettingsStore.commit.setRZStichworteDetails({ [field.key]: { spezialFallAlarm: field.value } });
                    },
                },
            },
            width: 180,
        },
        {
            headerName: 'Spezial-Fall-Anzeige',
            headerComponent: 'HelpTooltipHead',
            headerComponentParams: {
                context: {
                    tooltipText: 'Dieser Inhalt wird angezeigt wenn das Stichwort vergeben ist',
                },
            },
            field: 'spezialFallAnzeige',
            valueGetter: params => { var _a, _b; return ({ value: (_a = params.data) === null || _a === void 0 ? void 0 : _a.details.spezialFallAnzeige, key: (_b = params.data) === null || _b === void 0 ? void 0 : _b.name }); },
            sortable: false,
            cellRenderer: 'InputCell',
            cellRendererParams: {
                context: {
                    clicked(field) {
                        editSettingsStore_1.editSettingsStore.commit.setRZStichworteDetails({ [field.key]: { spezialFallAnzeige: field.value } });
                    },
                },
            },
            width: 270,
        },
        {
            headerName: '',
            resizable: false,
            flex: 1,
        },
        {
            headerName: '',
            field: 'actions',
            cellRenderer: 'DeleteButtonCell',
            width: 50,
            resizable: false,
            cellRendererParams: {
                context: {
                    clicked(field) {
                        editSettingsStore_1.editSettingsStore.commit.deleteRzStichworte([field.row.name]);
                    },
                },
            },
        },
    ];
}
exports.settingsDiversRechenzentrumColumnsDefs = settingsDiversRechenzentrumColumnsDefs;
