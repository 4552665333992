"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.find.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.function.name.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var lodash_1 = require("lodash");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

var staticDataMetricsStore_1 = require("@/state/staticDataMetricsStore");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      newExtrapageName: '',
      selectedExtraPageName: '',
      rules: {
        required: function required(v) {
          return !(0, lodash_1.find)(editSettingsStore_1.editSettingsStore.getters.extrapages, function (value) {
            return value.name === v.name;
          }) || 'Pflichtfeld';
        }
      }
    };
  },
  computed: {
    patExtraPageFelder: function patExtraPageFelder() {
      return staticDataMetricsStore_1.staticDataMetricsStore.state.params.patextrapagefields;
    },
    extraPages: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.extrapages;
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.updateExtraPages(value);
      }
    },
    selectedExtraPageNameNew: {
      get: function get() {
        var _this = this;

        var _a, _b;

        return (_b = (_a = editSettingsStore_1.editSettingsStore.getters.extrapages) === null || _a === void 0 ? void 0 : _a.find(function (value) {
          return value.name === _this.selectedExtraPageName;
        })) === null || _b === void 0 ? void 0 : _b.name;
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.updateExtraPageName({
          old: this.selectedExtraPageName,
          "new": value
        });
        this.selectedExtraPageName = value;
      }
    },
    selectedExtraPageEweUnterschrieben: {
      get: function get() {
        var _this2 = this;

        var _a, _b;

        return (_b = (_a = editSettingsStore_1.editSettingsStore.getters.extrapages) === null || _a === void 0 ? void 0 : _a.find(function (value) {
          return value.name === _this2.selectedExtraPageName;
        })) === null || _b === void 0 ? void 0 : _b.eweFieldname;
      },
      set: function set(value) {
        if (this.selectedExtraPageName) {
          editSettingsStore_1.editSettingsStore.commit.updateExtraPage({
            name: this.selectedExtraPageName,
            eweFieldname: value
          });
        }
      }
    },
    selectedExtraPageAvaDatumFieldName: {
      get: function get() {
        var _this3 = this;

        var _a, _b;

        return (_b = (_a = editSettingsStore_1.editSettingsStore.getters.extrapages) === null || _a === void 0 ? void 0 : _a.find(function (value) {
          return value.name === _this3.selectedExtraPageName;
        })) === null || _b === void 0 ? void 0 : _b.avaDatumFieldName;
      },
      set: function set(value) {
        if (this.selectedExtraPageName) {
          editSettingsStore_1.editSettingsStore.commit.updateExtraPage({
            name: this.selectedExtraPageName,
            avaDatumFieldName: value
          });
        }
      }
    },
    selectedExtraPageAvaErgebnisFieldName: {
      get: function get() {
        var _this4 = this;

        var _a, _b;

        return (_b = (_a = editSettingsStore_1.editSettingsStore.getters.extrapages) === null || _a === void 0 ? void 0 : _a.find(function (value) {
          return value.name === _this4.selectedExtraPageName;
        })) === null || _b === void 0 ? void 0 : _b.avaErgebnisFieldName;
      },
      set: function set(value) {
        if (this.selectedExtraPageName) {
          editSettingsStore_1.editSettingsStore.commit.updateExtraPage({
            name: this.selectedExtraPageName,
            avaErgebnisFieldName: value
          });
        }
      }
    },
    selectedExtraPageAvaErgebnisPositiv: {
      get: function get() {
        var _this5 = this;

        var _a, _b;

        return (_b = (_a = editSettingsStore_1.editSettingsStore.getters.extrapages) === null || _a === void 0 ? void 0 : _a.find(function (value) {
          return value.name === _this5.selectedExtraPageName;
        })) === null || _b === void 0 ? void 0 : _b.avaErgebnisPositiv;
      },
      set: function set(value) {
        if (this.selectedExtraPageName) {
          editSettingsStore_1.editSettingsStore.commit.updateExtraPage({
            name: this.selectedExtraPageName,
            avaErgebnisPositiv: value
          });
        }
      }
    },
    selectedExtraPageNoRzFieldname: {
      get: function get() {
        var _this6 = this;

        var _a, _b;

        return (_b = (_a = editSettingsStore_1.editSettingsStore.getters.extrapages) === null || _a === void 0 ? void 0 : _a.find(function (value) {
          return value.name === _this6.selectedExtraPageName;
        })) === null || _b === void 0 ? void 0 : _b.noRzFieldname;
      },
      set: function set(value) {
        if (this.selectedExtraPageName) {
          editSettingsStore_1.editSettingsStore.commit.updateExtraPage({
            name: this.selectedExtraPageName,
            noRzFieldname: value
          });
        }
      }
    },
    isDuplicate: function isDuplicate() {
      var _this7 = this;

      return !(0, lodash_1.find)(editSettingsStore_1.editSettingsStore.getters.extrapages, function (value) {
        return value.name === _this7.newExtrapageName;
      }) || 'Name ist bereits vorhanden';
    },
    isInactive: function isInactive() {
      return !!(0, lodash_1.find)(editSettingsStore_1.editSettingsStore.getters.rechenzentrumReihenfolgeInactive, function (x) {
        return x.name === 'extraseiten';
      });
    }
  },
  methods: {
    addExtrapage: function addExtrapage(event) {
      event.preventDefault();

      if (this.newExtrapageName && this.isDuplicate === true) {
        editSettingsStore_1.editSettingsStore.commit.addExtraPage({
          name: this.newExtrapageName
        });
        this.selectedExtraPageName = this.newExtrapageName;
        this.newExtrapageName = undefined;
      }
    },
    deleteExtrapage: function deleteExtrapage() {
      editSettingsStore_1.editSettingsStore.commit.deleteExtraPage(this.selectedExtraPageName);
      this.newExtrapageName = undefined;
    },
    log: function log(evt) {
      console.log(evt);
    }
  }
});