/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./RechenzentrumStichworte.vue?vue&type=template&id=196c4d08&scoped=true&lang=pug&"
import script from "./RechenzentrumStichworte.vue?vue&type=script&lang=ts&"
export * from "./RechenzentrumStichworte.vue?vue&type=script&lang=ts&"
import style0 from "./RechenzentrumStichworte.vue?vue&type=style&index=0&id=196c4d08&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "196c4d08",
  null
  
)

export default component.exports