"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsDiversZusatzversicherungColumnsDefs = void 0;
const editSettingsStore_1 = require("@/state/settings/editSettingsStore");
const staticDataMetricsStore_1 = require("@/state/staticDataMetricsStore");
function settingsDiversZusatzversicherungColumnsDefs() {
    return [
        {
            headerName: 'Stichwort',
            sortable: true,
            cellRenderer: 'StichwortCell',
            valueGetter: params => { var _a; return params.data && ((_a = staticDataMetricsStore_1.staticDataMetricsStore.state.params.stichworteMap) === null || _a === void 0 ? void 0 : _a[params.data]); },
            sort: 'asc',
            width: 250,
        },
        {
            headerName: 'Anzeigename',
            valueGetter: params => { var _a; return params.data && { value: (_a = editSettingsStore_1.editSettingsStore.getters.zvStichworte) === null || _a === void 0 ? void 0 : _a[params.data], key: params.data }; },
            cellRenderer: 'InputCell',
            cellRendererParams: {
                context: {
                    clicked(field) {
                        editSettingsStore_1.editSettingsStore.commit.setZvStichwortText({ name: field.key, text: field.value });
                    },
                },
            },
            resizable: false,
            sortable: true,
            flex: 1,
        },
        {
            headerName: '',
            cellRenderer: 'DeleteButtonCell',
            cellStyle: { justifyContent: 'end' },
            resizable: false,
            width: 50,
            cellRendererParams: {
                context: {
                    clicked(field) {
                        editSettingsStore_1.editSettingsStore.commit.deleteZvStichwort(field.row);
                    },
                },
            },
        },
    ];
}
exports.settingsDiversZusatzversicherungColumnsDefs = settingsDiversZusatzversicherungColumnsDefs;
