"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.function.name.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {};
  },
  computed: {
    rechzentrumReihenfolgeActive: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.rechzentrumReihenfolgeOrdered;
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.updateRechenzentrumReihenfolge(value.map(function (v) {
          return v.name;
        }));
      }
    },
    rechzentrumReihenfolgeInactive: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.rechenzentrumReihenfolgeInactive;
      },
      set: function set(value) {
        console.log(value);
      }
    },
    rechzentrumAvaTageGueltig: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.rechzentrumAvaTageGueltig;
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.setRechzentrumAvaTageGueltig(value);
      }
    },
    rechzentrumEweMonateGueltig: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.rechzentrumEweMonateGueltig;
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.setRechzentrumEweMonateGueltig(value);
      }
    },
    rechzentrumMinderjaehrigeIgnorieren: {
      get: function get() {
        return editSettingsStore_1.editSettingsStore.getters.rechzentrumMinderjaehrigeIgnorieren;
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.setRechzentrumMinderjaehrigeIgnorieren(value);
      }
    }
  },
  methods: {
    getNiceName: function getNiceName(key) {
      switch (key) {
        case "extraseiten":
          return "Extra-Seiten";

        case "stichworte":
          return "Stichworte";

        default:
          return key;
      }
    },
    log: function log(msg) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      console.log(msg);
    }
  }
});