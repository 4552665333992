"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  name: 'TestPatientenIds',
  data: function data() {
    return {
      loading: true
    };
  },
  computed: {
    testPatientenIds: {
      get: function get() {
        var _a;

        return ((_a = editSettingsStore_1.editSettingsStore.state.editSettings.datensammlung) === null || _a === void 0 ? void 0 : _a.testPatientenIds) || [];
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.setTestPatientenIds(value);
      }
    }
  },
  beforeMount: function beforeMount() {
    this.loading = false;
  }
});